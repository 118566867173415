import { getPageUrl } from '../Routes';

export const menuItems = [
  {
    label: 'Articles',
    url: getPageUrl('HomePage'),
  },
  {
    label: 'Blog',
    url: getPageUrl('HomePage'),
  },
  {
    label: 'Partenaires',
    url: getPageUrl('HomePage'),
  },
  {
    label: 'Contact',
    url: getPageUrl('HomePage'),
  },
  {
    label: 'À propos',
    url: getPageUrl('HomePage'),
  },
  {
    label: 'Panier',
    url: getPageUrl('HomePage'),
  },
];
