import React from 'react';
import PropTypes from 'prop-types';
import { Grid, AppBar, Toolbar, Typography, withStyles } from '@material-ui/core';

import { Link, spacing } from '../../styledComponents';
import { menuItems } from '../../../resources/menuItems';
import { getPageUrl } from '../../../Routes';

import logo from '../../../assets/logo/favicon.png';

const menuStyle = () => ({
  item: {
    fontFamily: 'Montserrat',
    whiteSpace: 'nowrap',
    fontSize: 15,
  },
  icon: {
    width: 12,
    marginRight: 5,
  },
});

const MenuItem = withStyles(menuStyle)(({ label, url, classes }) => (
  <Typography className={classes.item} color="textPrimary" variant="h6">
    <Link to={url} key={label}>
      {label}
    </Link>
  </Typography>
));

const MenuBar = ({ classes }) => {
  const middleIndex = Math.floor(menuItems.length / 2);
  const leftMenuItems = menuItems.slice(0, middleIndex);
  const rightMenuItems = menuItems.slice(middleIndex);

  return (
    <AppBar position="static" className={classes.appContainer}>
      <Toolbar className={classes.toolbar}>
        <Grid className={classes.itemsContainer} container alignItems="center" justify="space-around">
          {leftMenuItems.map(({ label, url }) => <MenuItem key={label} label={label} url={url} />)}
          <Link to={getPageUrl('HomePage')}>
            <Grid container alignItems="center">
              <img className={classes.logo} src={logo} alt="Portfolio - Mamadou Ouologuem" />
            </Grid>
          </Link>
          {rightMenuItems.map(({ label, url }) => <MenuItem key={label} label={label} url={url} />)}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

MenuBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

const style = () => ({
  appContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  toolbar: {
    width: '100%',
  },
  itemsContainer: {
    padding: spacing(0, 6),
  },
  logo: {
    width: '45px',
  },
});

export default withStyles(style)(MenuBar);
