import React from 'react';
import classnames from 'classnames';
import { createMuiTheme, useMediaQuery, withStyles } from '@material-ui/core';
import { pick } from 'lodash';
import { styled } from '@material-ui/styles';
import { Link as GatsbyLink } from 'gatsby';

export const contentWidth = 1280;
export const contentWidthPixels = `${contentWidth}px`;
export const mobileThreshold = 800;
export const mobileThresholdPixels = `${mobileThreshold}px`;
export const menuHeightOffset = 64;

export const colors = {
  darkGrey: '#333333',
  grey: '#DAD7D6',
  brown: '#67443B',
  white: '#FFFFFF',
};

const theme = createMuiTheme();
export const spacing = (factorTop, factorRight, factorBottom, factorLeft) => {
  const getSpacing = (factor) => (factor === undefined) ? '' : `${theme.spacing(factor)}px`;
  return `${getSpacing(factorTop)} ${getSpacing(factorRight)} ${getSpacing(factorBottom)} ${getSpacing(factorLeft)}`;
};
export const fontSizing = (factor) => `${factor * 7}px`;

export const defaultTheme = createMuiTheme({
  palette: {
    primary: { main: `${colors.white}` },
    secondary: { main: `${colors.brown}` },
    text: {
      primary: colors.darkGrey,
      secondary: colors.brown,
    },
  },
  overrides: {
    MuiTypography: {
      h1: {
        color: colors.grey,
        fontFamily: 'RealityShift',
        fontSize: fontSizing(14),
        letterSpacing: 0,
        [theme.breakpoints.down('xs')]: {
          fontSize: fontSizing(6),
          lineHeight: '60px',
        },
      },
      subtitle1: {
        color: colors.white,
        fontFamily: 'Montserrat',
        fontSize: fontSizing(3),
        fontStyle: 'italic',
      },
      h2: {
        color: colors.darkGrey,
        fontFamily: 'RealityShift',
        fontSize: fontSizing(11),
        marginBottom: spacing(5),
        letterSpacing: 0,
        textAlign: 'center',

        [theme.breakpoints.down('xs')]: {
          fontSize: fontSizing(6),
          lineHeight: '60px',
        },
      },
      subtitle2: {
        width: '50%',
        textAlign: 'center',
        fontFamily: 'Montserrat',
        color: colors.darkGrey,
        margin: 'auto',
        marginTop: spacing(-5),
        marginBottom: spacing(5),
        fontSize: fontSizing(2),
        fontStyle: 'italic',
      },
      body1: {
        color: colors.darkGrey,
        fontFamily: 'Montserrat',
        fontSize: fontSizing(2.5),
        lineHeight: '28px',
        [theme.breakpoints.down('xs')]: {
          fontSize: fontSizing(2),
        },
      },
    },
    MuiButton: {
      root: {
        color: colors.white,
        backgroundColor: colors.darkGrey,
        fontSize: fontSizing(2),
        fontWeight: '600',
        fontFamily: 'Montserrat',
        letterSpacing: '0.5px',
        lineHeight: '18px',
        minHeight: '40px',
        minWidth: '152px',
        textAlign: 'center',
        textTransform: 'none',
        border: `2px solid ${colors.darkGrey}`,
        borderRadius: '20px',
        '&:hover': {
          color: colors.darkGrey,
          backgroundColor: 'transparent',
          borderColor: colors.darkGrey,
        },
      },
      text: {
        padding: `6px ${spacing(4)}`,
      },
    },
    MuiToolbar: {
      regular: {
        minHeight: 0,
      },
    },
  },
});

const stylesBase = {
  muiGridBlockContainer: {
    margin: spacing(12, 0),
  },
  muiButtonWhiteBackground: {
    backgroundColor: colors.white,
    color: colors.darkGrey,
    border: `2px solid ${colors.white}`,
    '&:hover': {
      color: colors.white,
      backgroundColor: 'transparent',
      borderColor: colors.white,
    },
  },
};

export const getStylesBase = (keys) => pick(stylesBase, keys);

export const useWidth = () => {
  const keys = [...defaultTheme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
};

export const Link = styled(GatsbyLink)({
  color: 'inherit',
  textDecoration: 'none',
});

export const EmojiSpan = withStyles({
  rootClass: {
    fontStyle: 'initial',
  },
})(({ classes: { rootClass }, label = 'emoji', children }) => (
  <span role="img" aria-label={label} className={rootClass}>{children}</span>
));

export const EmphSpan = withStyles({
  rootClass: {
    '&.underlined': {
      borderBottom: `2px solid ${colors.brown}`,
    },

    '&.bolded': {
      fontWeight: 500,
    },
  },
})(({ classes: { rootClass }, option, children }) => (
  <span className={classnames(rootClass, option || 'underlined')}>{children}</span>
));

// Shapes
export const Arrow = styled('div')({
  boxSizing: 'border-box',
  height: '40px',
  width: '40px',
  border: `4px solid ${colors.darkGrey}`,
  borderWidth: '0px 0px 4px 4px',
  transform: 'rotate(315deg)',
  '&:hover': { borderColor: colors.white },
});
